import React, {Component} from "react"
import Layout from "../components/layout"
import TextField from "@material-ui/core/TextField/TextField"
import CustomCard from "../components/common-design/custom-card"
import Button from "@material-ui/core/Button"
import ArrowNavigationIcon from "@material-ui/icons/NearMe"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"
import ArrowRightIcon from "@material-ui/icons/ArrowForward"
import WaveSvg from "../assets/images/Wave.svg"
import {isValidContentfulImage, isValidPhone, isValidSectionField} from "../common/utils/CommonUtils"
import {graphql} from "gatsby"
import SEO from "../components/seo"
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import {BranchUtils} from "../common/utils/BranchUtils";
import BreadCrumbs from "../components/bread-crumbs";
import "./courses.scss";


const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    },
    {
        title: "Get Started",
        link: "/get-started/",
    },

]

class SupportGroups extends Component {

    constructor(props) {

        super(props);
        this.state = {
            error: false,
            contentLoaded: false,
            SearchedArticles: [],
            questions: [],
            searchLibraryValue: "",
            Searched: false,
            loader: {
                options: {
                    position: "absolute",
                },
            },
            phone: "",
            email: "",
            success: false,
            entities: [],
        };

        this.pageData = this.getFirstContentfulNode(this.props,
            "allContentfulGroupsLandingPage")
    }

    getFirstContentfulNode = (props, key) => {
        return props.data[key].nodes[0]
    };

    componentDidMount() {
    }

    sendPhone(event, phoneNumber) {
        event.preventDefault();
        const phone = isValidPhone(phoneNumber);
        if (phone) {
            SegmentAnalytics.track(SegmentEvents.GROUP_LANDING_PAGE, {
                phone,
                'formLocation': 'group-landing-page',
                appRequested: true,
            });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': 'group-landing-page',
                'event': 'GET_THE_APP',
                'phone': phone
            });

            let fromPage = 'group-landing-page';

            BranchUtils.desktopSendSMS(phone, fromPage, 'Header CTA', 'group-landing-page');

            this.setState({
                success: true,
            })
        } else {
            console.log("this.state.error", this.state.error);
            this.setState({
                error: true,
                success: false,
                phone: "",
            })
        }
    };

    render() {
        const {topSection, shortSections, groups} = this.pageData;
        return (
            <Layout location={this.props.location}>
                <SEO title="Groups"/>
                <BreadCrumbs currentPage={"Support Groups"} breadCrumbsList={breadCrumbsList}/>
                <div className="new-common-top-header">
                    <div className="new-common-top-inner">
                        {
                            isValidContentfulImage(topSection.imageOrVideo) && (
                                <img
                                    className="new-common-top-hero-img provider-details"
                                    src={topSection.imageOrVideo.file.url}
                                    alt="iPhone"/>
                            )

                        }
                        <div className="new-common-top-pattern-content">
                            {
                                isValidSectionField(topSection, "title") &&
                                <h2 className="new-common-top-main-head">{topSection.title}</h2>
                            }

                            {
                                isValidSectionField(topSection, "description")
                                &&
                                <p dangerouslySetInnerHTML={{__html: topSection.description.description}}
                                   className="new-common-top-sub-head">
                                </p>
                            }

                            <form method="post" onSubmit={(event) => {
                                this.sendPhone(event, this.state.phone)
                            }}>
                                <div className="get-app-input-wrap">
                                    <div className="input-box">
                                        <TextField
                                            id="number-input"
                                            className="number-field"
                                            placeholder="Your phone number"
                                            margin="normal"
                                            value={this.state.phone}
                                            onChange={event => {
                                                const {value} = event.target;
                                                this.setState({
                                                    phone: value,
                                                    error: false,
                                                    success: false
                                                });
                                            }}
                                        />
                                    </div>
                                    <Button className="blue-arrow-btn"
                                            type="submit"
                                    >
                                        Get the App <ArrowNavigationIcon/>
                                    </Button>
                                    {this.state.error ? <p
                                        className="error-msg">Please Enter Valid
                                        Phone Number</p> : null}
                                    {this.state.success ? <p
                                        className="success-msg">Got it! Sending you a text with the link</p> : null}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                <div className="common-wave-wrapper"
                     style={{backgroundImage: `url(${WaveSvg})`}}>
                    <img className="confetti-bg"
                         src={require("../assets/images/confeti-pattern.svg")}
                         alt="Pattern"/>
                    <div className="wave-bg">
                        <div className="common-wave-inner">
                            <h2 className="common-wave-heading">Find the Support
                                Group that's right for you</h2>
                            <p className="common-wave-des">Groups are formed
                                around topics, demographics, or
                                communities.
                                If you don’t see what you’re looking for, let’s
                                get a new group started!</p>
                            {groups && groups.length > 0 && (
                                <CustomCard newdata={groups} type="groups"/>
                            )}
                            <Button href="/support-groups/online-support-groups/"
                                    className="new-blue-link"
                                    color="primary">
                                See all of the Confidant Support
                                Groups<ArrowForwardThinIcon/>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="common-feature-wrapper">
                    <div className="common-feature-inner">
                        <h2 className="common-feature-head">What makes Confidant
                            Special</h2>
                        <p className="common-feature-des">We built the
                            technology and the network from the ground up to
                            support a better future for healthcare.</p>

                        {

                            shortSections && shortSections.map(
                                (section, index) => (
                                    <div key={"key-" + section.heading}
                                         className="common-feature-single-feature">
                                        {
                                            index % 2 !== 0 && (
                                                <div
                                                    className="common-feature-img-side">
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="common-feature-image"
                                                            src={section.image.file.url}
                                                            alt="Frame"/>)

                                                    }

                                                </div>
                                            )
                                        }
                                        <div
                                            className="common-feature-text-side">
                                            {
                                                isValidSectionField(section,
                                                    "heading") &&
                                                <h2 className="common-feature-heading">
                                                    {section.heading}
                                                </h2>
                                            }
                                            {
                                                isValidSectionField(section,
                                                    "description") &&
                                                <p className="common-feature-para"
                                                   dangerouslySetInnerHTML={{__html: section.description.description}}>
                                                </p>
                                            }
                                        </div>
                                        {
                                            (index === 0 || index % 2 === 0)
                                            && (
                                                <div
                                                    className="common-feature-img-side"
                                                    style={{textAlign: "right"}}>
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="common-feature-image"
                                                            src={section.image.file.url}
                                                            alt="Frame"/>
                                                    )
                                                    }
                                                </div>
                                            )
                                        }

                                    </div>
                                ))
                        }
                    </div>
                </div>

            </Layout>
        )
    }

}

export default SupportGroups

export const query = graphql`
 query getGroupLandingData {
  allContentfulGroupsLandingPage {
    nodes {
     groups {
        groupName
        memberCount
        groupImage {
          file {
            url
          }
          title
        }
        groupDescription
        slug
      }
    
          shortSections {
           ... on ContentfulShortSection{
        heading
        image {
          file {
            url
          }
          description
        }
        description {
          description
        }
      }
    }
      topSection {
      title
      description{
      description
      }
        imageOrVideo {
          file {
            url
          }
        }
      }
    }
  }
}
`;


